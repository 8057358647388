@font-face {
  font-family: 'DigitalClock';
  src: url('/Digital Dismay.otf') format('truetype');
}

body {
  background:#7dbe9c!important;
  -webkit-user-select: none; /* Désactive la sélection de texte sur Safari et Chrome */
  -moz-user-select: none;    /* Désactive la sélection de texte sur Firefox */
  -ms-user-select: none;     /* Désactive la sélection de texte sur Internet Explorer/Edge */
  user-select: none;         /* Désactive la sélection de texte sur les navigateurs supportant la spécification */
}

/* Désactive la sélection de texte pour tous les éléments */
* {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img {
  -webkit-user-drag: none;
  -webkit-touch-callout: none; /* Empêche le menu contextuel sur iOS lors de l'appui long */
}

.pulse-red {
	border-radius: 50%;
	/*transform: scale(1);*/
	background: rgba(255, 82, 82, 1);
	/*box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);*/
	/*animation: pulse-red 1s infinite ease-in-out ;*/
}

.pulse-yellow {
	border-radius: 50%;
	/*transform: scale(1);*/
	background: #d69e2e;
	/*box-shadow: 0 0 0 0 #d69e2e;close*/
	/*animation: pulse-yellow 2s infinite ease-in-out ;*/
}

.pulse-green {
	border-radius: 50%;
	/*transform: scale(1);*/
	background: #38a169;
	/*box-shadow: 0 0 0 0 #38a169;*/
	/*animation: pulse-green 1.5s infinite ease-in-out ;*/
}

.sonar {
	border-radius: 50%;
	transform: scale(1);
	background: #38a169;
	box-shadow: 0 0 0 0 #38a169;
	/*animation: pulse-green 3s infinite ease-in-out ;*/
}

.close {
	animation: pulse-green 0.25s infinite ease-in-out ;
}

.pulse-white {
	border-radius: 50%;
	/*transform: scale(1);*/
	background: white;
	/*box-shadow: 0 0 0 0 white;*/
	/*animation: pulse-white 1s infinite ease-in-out ;*/
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #e53e3e;
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}

@keyframes pulse-yellow {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #d69e2e;
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}

@keyframes pulse-green {
	0% {
		transform: scale(0.97);
		box-shadow: 0 0 0 0 #38a169;
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(0.97);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}

@keyframes pulse-white {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 white;
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

.eating-progress div {
  transition: 1s;
}

.vertical-shake {
	animation: vertical-shaking 1s infinite ease-in-out ;
}

@keyframes vertical-shaking {
	0% { transform: translateY(0) }
	25% { transform: translateY(3px) }
	50% { transform: translateY(-3px) }
	75% { transform: translateY(3px) }
	100% { transform: translateY(0) }
   }

.chat-bubble::before {
	content: '';
	position: absolute;
	bottom: 17px; /* Ajustez cette valeur pour placer la flèche verticalement */
	right: -13px; /* Ajustez cette valeur pour placer la flèche horizontalement */
	border-width: 7px;
	border-style: solid;
	border-color: transparent transparent transparent white; /* Adjust the colors as needed */
}

@keyframes quiet {
    25%{
      transform: scaleY(.6);
    }
    50%{
      transform: scaleY(.4);
    }
    75%{
      transform: scaleY(.8);
    }
  }
  
  @keyframes normal {
    25%{
      transform: scaleY(1);
    }
    50%{
      transform: scaleY(.4);
    }
    75%{
      transform: scaleY(.6);
    }
  }
  @keyframes loud {
    25%{
      transform: scaleY(1);
    }
    50%{
      transform: scaleY(.4);
    }
    75%{
      transform: scaleY(1.2);
    }
  }
  
.boxContainer{
    display: flex;
    justify-content: space-between;
    height: 25px;
    --boxSize: 5px;
    --gutter: 3px;
    width: calc((var(--boxSize) + var(--gutter)) * 10);
  }
  
  .box{
    height: 100%;
    width: var(--boxSize);
    background: #2c2c2c;
    
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    border-radius: 8px;
  }
  
  .box1{
    animation-name: quiet;
    transform: scaleY(.4);
    animation-duration: 0.9s;
  }
  
  .box2{
    animation-name: normal;
    transform: scaleY(.5);
    animation-duration: 1.1s;
  }
  
  .box3{
    animation-name: quiet;
    transform: scaleY(.7);
    animation-duration: 0.8s;
  }
  
  .box4{
    animation-name: loud;
    transform: scaleY(1);
    animation-duration: 0.5s;
  }
  
  .box5{
    animation-name: quiet;
    transform: scaleY(.6);
    animation-duration: 1s;
  }

  .stop .box{
    animation-duration:0s;
  }

  input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}

@keyframes shake {
	0%, 100% {
		transform: rotate(0deg);
	  }
	  25% {
		transform: rotate(-1deg);
	  }
	  50% {
		transform: rotate(1deg);
	  }
	  75% {
		transform: rotate(-1deg);
	  }
  }
  
  .shake {
	animation: shake 0.25s ease-in-out 2; /* Durée totale de l'animation est 0.5s, répétée 2 fois */
  }

  @keyframes sweep { to { transform: rotate(360deg); } }

  .sweep{
	position: absolute;
	box-sizing: border-box;
    border-right: solid 1px hsla(145, 50%, 40%, .3);    
    border-radius: 100% 0 0 0;
    transform-origin: 100% 100%;
    background: linear-gradient(50deg, rgba(34, 34, 34, 0) 56%, #12bb4e);
    animation: sweep 3s infinite linear;
  }

  .rain-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .rain {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .rain.back-row {
    opacity: 0.5;
    bottom: 60px;
    display: none;
  }
  
  .back-row-active .rain.back-row {
    display: block;
  }
  
  .drop {
    position: absolute;
    bottom: 100%;
    width: 15px;
    height: 120px;
    animation: drop 0.5s linear infinite;
  }
  
  @keyframes drop {
    0% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(90vh);
    }
    100% {
      transform: translateY(90vh);
    }
  }
  
  .stem {
    width: 1px;
    height: 60%;
    margin-left: 7px;
    background: linear-gradient(to bottom, rgba(241, 241, 241, 0.171), rgba(151, 148, 148, 0.555));
    animation: stem 0.5s linear infinite;
  }
  
.isNight .stem {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.25));
}

  @keyframes stem {
    0% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
  .splat {
    width: 15px;
    height: 10px;
    border-top: 2px dotted rgba(59, 57, 57, 0.795);
    border-radius: 50%;
    opacity: 0;
    transform: scale(0);
    animation: splat 0.5s linear infinite;
    display: none;
  }
  
  .isNight .splat{
    border-top: 2px dotted rgba(255, 255, 255, 0.5);
  }

  .splat-active .splat {
    display: block;
  }
  
  @keyframes splat {
    0% {
      opacity: 1;
      transform: scale(0);
    }
    80% {
      opacity: 1;
      transform: scale(0);
    }
    90% {
      opacity: 0.5;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1.5);
    }
  }
  
  .toggles {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .toggle {
    width: 50px;
    height: 50px;
    line-height: 51px;
    text-align: center;
    font-family: sans-serif;
    font-size: 10px;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
    user-select: none;
  }
  
  .toggle:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }
  
  .toggle:active {
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  .toggle.active {
    background-color: rgba(255, 255, 255, 0.4);
  }

  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overscroll-behavior-y: contain;
  }

  /* Conteneur pour les particules */
.nuage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Empêche d'interagir avec le nuage */
  z-index: 1; /* Assure que le nuage est au-dessus de la carte */
  border-radius: 10px; /* Assure que le nuage a les mêmes coins arrondis que la carte */
}

/* Particules du nuage */
.nuage .particule {
  position: absolute;
  border-radius: 50%;
  background: rgba(0, 255, 0, 0.5); /* Couleur du nuage vert avec opacité */
  opacity: 0;
  animation: particule-animation 1.5s ease-out;
  filter: blur(2px);
}

/* Animation des particules */
@keyframes particule-animation {
  0% {
    transform: scale(0);
    opacity: 0.9;
  }
  50% {
    transform: scale(3.5); /* Les particules deviennent plus grandes */
    opacity: 0.3;
  }
  100% {
    transform: scale(3); /* Les particules deviennent encore plus grandes */
    opacity: 0;
  }
}

/* Ajoute des particules aléatoires */
.nuage .particule:nth-child(1) { bottom: 0%; right: 0%; width: 30px; height: 30px; animation-delay: 0s; }
.nuage .particule:nth-child(2) { bottom: 10%; right: 5%; width: 40px; height: 40px; animation-delay: 0.2s; }
.nuage .particule:nth-child(3) { bottom: 20%; right: 10%; width: 50px; height: 50px; animation-delay: 0.4s; }
.nuage .particule:nth-child(4) { bottom: 30%; right: 15%; width: 75px; height: 75px; animation-delay: 0.6s; }
.nuage .particule:nth-child(5) { bottom: 250%; right: 7.5%; width: 100px; height: 100px; animation-delay: 0.8s; }

.particule:nth-child(1) {
  width: 50px;
  height: 50px;
  top: 70%;
  right: 10%;
  border-radius: 50% 50% 0 0; /* Donne une forme irrégulière */
  transform: rotate(45deg);
  animation-delay: 0s;
}
.particule:nth-child(2) {
  width: 70px;
  height: 70px;
  top: 60%;
  right: 20%;
  border-radius: 50% 50% 0 0; /* Donne une forme irrégulière */
  transform: rotate(30deg);
  animation-delay: 0.2s;
}
.particule:nth-child(3) {
  width: 60px;
  height: 60px;
  top: 80%;
  right: 15%;
  border-radius: 50% 50% 0 0; /* Donne une forme irrégulière */
  transform: rotate(-30deg);
  animation-delay: 0.4s;
}
.particule:nth-child(4) {
  width: 40px;
  height: 40px;
  top: 90%;
  right: 25%;
  border-radius: 50% 50% 0 0; /* Donne une forme irrégulière */
  transform: rotate(-15deg);
  animation-delay: 0.6s;
}
.particule:nth-child(5) {
  width: 80px;
  height: 80px;
  top: 50%;
  right: 5%;
  border-radius: 50% 50% 0 0; /* Donne une forme irrégulière */
  transform: rotate(60deg);
  animation-delay: 0.8s;
}

.rotating {  
  /*animation: rotate 2s linear infinite;*/
}

.bonus {  
  animation: zoomIn 1s ease-out, flipVertical 0.75s linear infinite;
}

.shadow-map {
  border-bottom: 2px solid #00000054;
}

@keyframes zoomIn {
  from {
    transform: scale(0); /* Taille initiale 0 */
  }
  to {
    transform: scale(1); /* Taille finale normale */
  }
}


@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

@keyframes flipVertical {
  from {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(-1);
  }
}
.radar-pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border: 7px solid #00ff0059;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 1.5s ease-out infinite;
  filter: blur(2px);
}

@keyframes pulse {
  0% {
      width: 10px;
      height: 10px;
      opacity: 1;
  }
  100% {
      width: 130px;
      height: 130px;
      opacity: 0.6;
  }
}

.store-front {
  width: 100%;
  height: 150px;
  background-color: #f5f5f5; /* Couleur du mur */
  display: flex;
  justify-content: center;
  align-items: center;
}

.store-front {
  width: 100%;
  height: 150px;
  background-color: #f5f5f5; /* Couleur du mur */
  display: flex;
  justify-content: center;
  align-items: center;
}

.awning {
  width: 100%;
  background-image:  linear-gradient(to top, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.9) 100%), 
  url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="120" height="100" viewBox="0 0 120 100"><rect width="60" height="100" fill="red" /><rect x="60" width="60" height="100" fill="white" /><path d="M0,90 Q30,120 60,90 Z" fill="red" /><path d="M60,90 Q90,120 120,90 Z" fill="white" /></svg>');

  background-size: 120px 100px; /* Correspond à la taille du motif SVG */
  background-repeat: repeat-x; /* Répéter horizontalement */
  color: black;
  filter: drop-shadow(2px 4px 6px black);
  background-position-x: 50%
}

.awning::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  background-image: url(/images/shopping.png);
  background-repeat: repeat-x;
  color: black;
  height: 28px;
  left: 0;
  transform: translate(0px, 28px);
  border-top: 1px solid #ffffff10;
  background-position-x: 50%
}

/* Voile de chaleur apparaissant du bas vers le haut */
.heat::after {
  content: '';
  position: absolute;
  bottom: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 165, 0, 0.95); /* Voile orange translucide */
  filter: blur(50px);
  animation: heatRise 4s ease-out ;
  z-index:100;
  left:0
}

/* Animation de montée du voile */
@keyframes heatRise {
  0% {
    bottom: -100%;
    opacity: 0;
  }
  25% {
    bottom: 0;
    opacity: 1;
  }
  100% {
    bottom: -100%;
    opacity: 0;
  }
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.float {
  animation: floating 2s ease-in-out infinite;
}

@keyframes backgroundZoomFade {
  0% {
    transform: scale(97%);
    opacity: 0.7;
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}

@keyframes bounce {
  50%{
    transform: scale(0.9);
  }
  100% {
    transform: scale(1); /* L'élément monte */
  }
  
}

.mainButton, .mainButton:focus-visible, .mainButton:focus {
  border-color:transparent;
  border-bottom: 5px solid #00000038;
  transition: all 0.1s ease-out;
  outline: none; 
}

.mainButton:active {
  border-bottom: 0px solid transparent;
  margin-top:2.5px;
  margin-bottom:-2.5px;
  transform:scaleY(0.9);
}

.paper-notes {
  width: 100%;
  padding: 35px 20px;
  background: repeating-linear-gradient(#f1ede93d, #f1ede936 31px, #94ACD4 31px, #94ACD4 32px);
  font-family: 'Shadows Into Light', cursive;
  line-height: 32px;
  outline: 0;
  font-size: 18px;
  color: #0336cdb5;
  position:relative;
}

.strikethrough-draw {
  position: relative;
  display: inline-block; /* Nécessaire pour que le pseudo-élément s'adapte à la largeur du texte */
}

.strikethrough-draw::after {
  content: "";
  position: absolute;
  bottom: 45%; /* Positionne le trait au centre verticalement */
  left: 0;
  width: 0; /* Commence avec une largeur de 0 */
  height: 4px; /* Épaisseur du trait */
  background-color: rgba(255, 0, 0, 0.562);
  animation: draw-line 1s ease-in-out forwards; /* Anime le trait */
}

.strikethrough-draw.no-animation::after {
  width: 100%;
  animation: none; /* Désactive l'animation */
}

@keyframes draw-line {
  from {
    width: 0;
  }
  to {
    width: 100%; /* Le trait s'étend sur toute la largeur du texte */
  }
}